export default {
    getFormattedDate(str, withTime) {
        let parts = str.split(' ')[0].split('-')
        let ret = `${parts[2]}.${parts[1]}.${parts[0]}`
        if (withTime && str.split(' ').length === 2) {
            let hrs, mins, secs;
            console.log(secs);
            [hrs, mins, secs] = str.split(' ')[1].split(':')
            ret += `, ${hrs}:${mins} Uhr`
        }
        return ret
    },

    getCategoriesString(categories) {
        let str = '';
        let i;
        for (i = 0; i < categories.length; i++) {
            str += categories[i].name;
            if (i < categories.length - 1) {
                str += ', ';
            }
        }
        return str;
    },

    getHTML(str, noConsent) {
        let twtrAttributes = "class=\"twitter-tweet\" data-lang=\"de\" data-dnt=\"true\" data-chrome=\"transparent\""
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

        if (prefersDarkMode || document.body.classList.contains('dark')) {
            twtrAttributes += " data-theme=\"dark\""
        }
        if (str && str.length) {
            str = str.replace(/href="#"/gi, 'href="javascript:void(0)"')
                .replace("<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>", '')
                .replace("class=\"twitter-tweet\"", twtrAttributes)
        
            if (noConsent && str.indexOf('twitter-tweet') !== -1) {
                return str + '<div class="error"><p>Um diesen Inhalt korrekt dargestellt zu bekommen, <a href="javascript:cc.showSettings();">akzeptieren Sie bitte Drittanbieter-Cookies</a>.</p></div>'
                // str = str.replace(/<blockquote class="twitter.*?<\/blockquote>/g, 'TWITTER')
                // let parts = str.split('<blockquote')
                // console.log(str)
                // console.log(parts)
                // str = '<p>boom</p>'
            }

            return str
        } else {
            return str
        }
    }
}
