<template>
  <ion-page>
    <Header name="RKI Feed" />
    <ion-content 
      :fullscreen="true"
      :scroll-events="true"
      @ionScroll="onScroll($event)"
    >
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div id="container">

        <ion-loading
          :is-open="isLoading"
          message="Lade Daten.."
          @onDidDismiss="setLoading(false)"
          :duration="3000"
        >
        </ion-loading>

        <ion-grid>

          <ion-row v-if="showNoEntries" class="no-data">
            <ion-col>
              <div class="card">
                <h3>Keine Einträge</h3>
              </div>
            </ion-col>
          </ion-row>

          <div v-else>
            <ion-row 
              v-for="(entry, index) in entries" 
              :key="index"
              @click="openExternalLink(entry.link)"
            >
              <ion-col>
                <div class="card news-entry">
                  <div class="content">
                    <div class="text">
                      <h3 class="font-face--merrisans light">{{ entry.title }}</h3>
                      <p class="font-face--merrisans light">{{ entry.description }}</p>
                    </div>
                  </div>
                  <div class="meta">
                    <time class="font-face--merrisans light">{{ getFormattedDate(entry.pubdate) }}</time>
                  </div>
                </div>
              </ion-col>
            </ion-row>

            <ion-row 
              v-if="showLoadMore" 
              class="load-more"
              :class="{'invisible': isLoading}"
            >
              <ion-col>
                <a href="#" class="btn btn-next btn-secondary" role="button" @click.prevent="loadMore(null, true)">
                  <span>Mehr laden</span>
                </a>
              </ion-col>
            </ion-row>
          </div>

        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent 
} from '@ionic/vue';
import { Plugins } from '@capacitor/core';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'RkiFeed',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent
  },
  data () {
    return {
      start: 1,
      isLoading: false,
      totalEntries: 0,
      entries: [],
      inAppBrowserOpen: false,
      lastScrollLoad: 0,
      firstLoadDone: false
    }
  },
  computed: {
    showLoadMore (): boolean {
      return (this as any).totalEntries > (this as any).entries.length
    },
    showNoEntries (): boolean {
      return !(this as any).isLoading && (this as any).firstLoadDone && !(this as any).entries.length 
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    onScroll (event: any) {
      const vm = (this as any);
      let shouldLoad = vm.shouldLoadMoreBasedOnScrollPosition();
      if (shouldLoad && !vm.isLoading && 
        event.detail.scrollTop > (vm.lastScrollLoad + (document.body.clientHeight / 2))
      ) {
          vm.lastScrollLoad = event.detail.scrollTop;
          console.log('loading new content due to scroll position');
          vm.loadMore(null, true);
        }
    },
    reset () {
      (this as any).start = 1;
      (this as any).isLoading = false;
      (this as any).totalEntries = 0;
      (this as any).entries = [];
      (this as any).inAppBrowserOpen = false;
      (this as any).lastScrollLoad = 0;
      (this as any).firstLoadDone = false;
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    async loadMore (event: any, loadOnClick: boolean) {
      console.log('start load');
      const vm = (this as any);

      if (loadOnClick) {
        console.log('loading new content due to user interaction');
      } else {
        vm.setLoading(true);
      }

      try {
        const data = await api.getRkiFeed(vm.start);
        console.log(data);

        if (data.data.entries.length) {
          for (let i in data.data.entries) {
            vm.entries.push(data.data.entries[i]);          
          }
          vm.totalEntries = data.data.totalEntries;
          vm.start += 1;
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      console.log(vm.entries);
      vm.setLoading(false);
      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      if (!(this as any).isLoading) {
        (this as any).reset();
        (this as any).loadMore(event);
      }
    },
    async openExternalLink (url: string) {
      (this as any).inAppBrowserOpen = true;
      const { Browser } = Plugins;
      await Browser.open({ url: url });
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave rki feed');
    (this as any).reset();
    (this as any).firstLoadDone = false;
  },
  ionViewDidEnter () {
    console.log('[ionView] entered rki feed');
    (this as any).doRefresh();
  },
  mounted () {
    const vm = (this as any);
    vm.doRefresh();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive && !vm.inAppBrowserOpen) {
        // vm.scrollTop();
        vm.doRefresh();
      }
    });
  }
}
</script>

<style scoped lang="less">
.load-more {
  margin-top: 15px;
}

.invisible {
  opacity: 0;
}

.hidden {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .card {
    p {
      color: #fff;
    }
  }
}
body.dark {
  .card {
    p {
      color: #fff;
    }
  }
}
</style>