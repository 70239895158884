export default {
    scrollTop() {
        setTimeout(() => {
            // Scrolle zum Seitenanfang
            const ionContent = document.querySelector('ion-router-outlet .ion-page:not(.ion-page-hidden) ion-router-outlet .ion-page:not(.ion-page-hidden) ion-content')
            const scrollable = ionContent?.shadowRoot?.querySelector('.inner-scroll') || null
            console.log(scrollable)
            scrollable && scrollable.scroll({ top: 0, left: 0, behavior: 'smooth' })
        }, 200)
    },
    shouldLoadMoreBasedOnScrollPosition () {
        let content = document.querySelector('ion-router-outlet .ion-page:not(.ion-page-hidden) ion-router-outlet .ion-page:not(.ion-page-hidden) ion-content');
        if (content) {
            let scrollable = content.shadowRoot?.querySelector('.inner-scroll');
            if (scrollable) {
                let bottomScroll = scrollable.clientHeight + scrollable.scrollTop;
                // console.log('scrollHeight', scrollable.scrollHeight);
                // console.log('clientHeight', scrollable.clientHeight);
                if (scrollable.scrollHeight > scrollable.clientHeight &&
                    (scrollable.scrollHeight - bottomScroll) <= 200) {
                    return true;
                }
            }
        }
        return false;
    },
    getImageUrl (path) {
        // return `https://picsum.photos/seed/${path.replace(/[^\w]/g, '')}/640/480`
        // return 'https://via.placeholder.com/640x480.png/0077cc?text=ukk'
        // return `https://api.uk-aktuell.sunzity.de${path}`
        return path
    },
    hasImage (entry) {
        return (entry.imageUrl && entry.imageUrl !== 'https://ukk-aktuell.uk-koeln.de') || (entry.image && entry.image !== 'https://ukk-aktuell.uk-koeln.de')
        // return entry.imageUrl && entry.imageUrl !== null
    }
}